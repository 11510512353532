.slider-container {
  width: 96vw;
  height : 65vw;
  min-height: 150px;
  max-width: 1000px;
  max-height:680px;
  margin: 0 auto ;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border: 3px solid var(--fonce);
  border-radius: 10px;
  box-shadow: 3px 3px 3px var(--fonce);
  position: relative;
  overflow: hidden;
  background-color: var(--fonce);
}

.arrow {
  font-size: 30px;
  position: absolute;
  cursor: pointer;
  color:var(--clair);
  background-color: var(--fonce);
  border-radius: 999px;
  @include flexMid;
  border : 3px solid var(--fonce);
  box-shadow: 0px 0px 5px 5px var(--clair);
}
.arr-left {
  top: 50%;
  transform: translateY(-50%);
  left: 2px;
}

.arr-right {
  top: 50%;
  transform: translateY(-50%);
  right: 2px;
}

.img-frame {
  width: 100%;
  height: 100%;
  padding: 20px 40px;
  min-height: 150px;
  border: 2px solid var(--clair);
  // background-color: violet;
  border-radius: 10px;
  transition: all 1s;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    opacity: 1;
    border-radius: 10px;
  }
  
}

.spacer{
    width: 100%;
    height: 200px;
    background-color: var(--clair);
}
