.navigation-container {
  width: 100%;
  height: 135px;
  background-color: var(--clair);
  padding-top: 20px;
  position: fixed;
  bottom: -35px;
  z-index: 10;
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 70px;
  margin: 0 auto;
  border-radius: 10px;
  background-color: var(--fonce);
  position: relative;

  ul {
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    li {
      position: relative;
      width: 70px;
      height: 70px;
      z-index: 1;

      a {
        position: relative;
        @include flexMid;
        flex-direction: column-reverse;
        width: 100%;
        text-align: center;
        font-weight: bold;
        color: var(--clair);
      }
    }
  }
}

.nav-icon {
  position: relative;
  display: block;
  line-height: 75px;
  font-size: 25px;
  text-align: center;
  z-index: 5;
}

a svg {
  transition: 0.5s;
}

a.active svg {
  transform: translateY(-39px);
  color: var(--fonce);
  font-size: 25px;
}

a .nav-text {
  position: absolute;
  transition: 0.5s;
  font-size: 14px;
  opacity: 0;
  transform: translateY(20px);
}

a.active .nav-text {
  opacity: 1;
  transform: translateY(15px);
  color: var(--clair);
}

.indicator {
  position: absolute;
  top: -53%;
  border-radius: 999px;
  width: 70px;
  height: 70px;
  background: var(--marron);
  border: 8px solid var(--clair);
  opacity: 0;
  transform: scale(0.1);
  transition: 0.5s;

  &::before {
    content: "";
    position: absolute;
    top: 56%;
    left: -22px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top-right-radius: 40px;
    box-shadow: 1px -10px 0 0 var(--clair);
  }
  &::after {
    content: "";
    position: absolute;
    top: 56%;
    right: -22px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top-left-radius: 22px;
    box-shadow: -1px -10px 0 0 var(--clair);
  }
}
a.active .indicator {
  display: block;
  transform: scale(1);
  opacity: 1;
}


.navigation-overlay{
  position: absolute;
  left: 0;top: 0;bottom:0;right: 0;
  background-color: var(--clair);
}