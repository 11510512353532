.mentions-container{
    h2{
        font-weight: bold;
        font-size: 18px;
        text-decoration: underline;
        margin-bottom: 8px;
    }
    p{
        font-size: 14px;
        
    }
}