@media screen and (min-width: 768px) {

    .entete {
        width: 90vw;
        margin-top: 150px;
    }

















}