@media screen and (min-width: 768px) {
  .mentions-container {
    h2 {
      font-weight: bold;
      font-size: 22px;
      text-decoration: underline;
      margin: 30px 0 20px;
    }
    p {
      font-size: 18px;
      text-align: justify;
    }
  }
}
