.partners {
    width: 100%;
    min-height: 250px;
    text-align: center;
    margin: 0 auto;
    position: relative;
    // border : 1px solid violet;

    .text_partner{
      margin-bottom: 80px;
    }
  
    .partner {
      width: 50%;
      min-width: 200px;
      margin: 0 auto;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      border: 3px solid var(--fonce);
      padding: 10px 20px;
      border-radius: 10px;
      box-shadow: 3px 3px 5px var(--fonce);
      font-weight: bold;
      transition: all 0.5s;
      // z-index: 30;
     
      &:nth-child(2) {
        background-color: #284b73;
        top: 50px;
        a {
          color: black;
          padding: 10px;
        }
      }
      &:nth-child(4) {
        background-color:#ed0015;
        top: 75%;
        transform: translateY(-30%) translateX(-50%);
        a {
          color: white;
          padding: 10px;
        }
      }
      
      &:hover {
        font-size: 20px;
        width: 60%;
  
        min-width: 250px;
        padding: 10px 30px;
        flex-shrink: 0;
        white-space: nowrap;
      }
    }
  }