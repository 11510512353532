@import "./libs/reset";
@import "./libs/variables";
@import "./libs/mixins";
@import "./sous-fichiers/contactbar";
@import "./sous-fichiers/contactbar_MQ";
@import "./sous-fichiers/entete";
@import "./sous-fichiers/entete_MQ";
@import "./sous-fichiers/fonctionnement";
@import "./sous-fichiers/home";
@import "./sous-fichiers/mentions";
@import "./sous-fichiers/mentions_MQ";
@import "./sous-fichiers/navbar";
@import "./sous-fichiers/navbar_MQ";
@import "./sous-fichiers/tarifs";
@import "./sous-fichiers/homeComponents";
@import "./sous-fichiers/homeComponents_MQ";
@import "./sous-fichiers/slider";

:root {
  --clair: #f9f9f9;
  --fonce: #111;
  --rose: #e752e7;
  --marron: #c07f2a;
}
html {
  background-color: var(--clair);
}

.App {
  width: 100%;
  min-height: 100vh;
  background-color: var(--clair);
  color: var(--fonce);
  border-top: 1px solid var(--fonce);
  position: relative;
}

.container-general {
  margin: 20px auto 150px;
  width: 96vw;
  max-width: 960px;
  padding: 10px ;
  background-color: var(--clair);
}

.container-general-bas-de-page {
  width: 100%;
  height: 150px;
  background-color: var(--clair);
}
